import React, { useContext } from 'react';
import { Switch } from 'react-router-dom';
import PublicRoute from './helpers/publicRoute';
import PrivateRoute from './helpers/privateRoute';
import { AuthContext } from './context/authContext';
import Login from './pages/login';
import Routes from './pages';
// import Development from './pages/development';

function Router() {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <Switch>
      <PrivateRoute path="/revolmatic/:view/:child?" component={Routes} isLoggedIn={isLoggedIn} />
      <PublicRoute restricted path="*" component={Login} isLoggedIn={isLoggedIn} redirectTo="/dashboard" />
    </Switch>
  );
}

export default Router;
