import styled from 'styled-components/macro';
import { ToastContainer } from 'react-toastify';

export const DashboardContentHolder = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #fafafa;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  position: relative;
  z-index: 1;
`;
export const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
`;

export const StyledToastContainer = styled(ToastContainer)`
  z-index: var(--z-90);

  .Toastify__toast {
    padding: 0;
    min-height: 0;
    border-radius: 8px;
    font-family: inherit;
  }
  .Toastify__toast--default {
    background: none;
  }
  .Toastify__toast-body {
    padding: 0;
  }
  .Toastify__close-button {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }
`;
