import React, { useContext, useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';
import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import MachineService from 'services/machineService';
import Switch from 'components/atoms/Switch';
import { SwitchHolerMachine } from './styles';

function MachineForm({ machine, passwordOnly, onClose = () => {} }) {
  const [form] = useForm();
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const { fetchMachines } = useContext(AuthContext);

  useEffect(() => {
    if (machine && !passwordOnly) {
      form.setFieldsValue({
        m_name: machine.m_name,
        m_generation: machine.m_generation,
        m_status: machine.m_status,
      });
      setState({
        m_name: machine.m_name,
        m_generation: machine.m_generation,
        m_status: machine.m_status,
      });
    }
  }, [machine, passwordOnly]);
  const onSubmit = async data => {
    try {
      setLoading(true);
      if (machine && !passwordOnly) {
        delete data.m_password;
        delete data.m_confirm_password;
        MachineService.updateMachine(machine._id, { ...data });
      } else if (machine && passwordOnly) {
        await MachineService.updateMachine(machine._id, {
          m_password: data.m_password,
        });
      } else {
        await MachineService.addMachine({
          ...data,
        });
      }
      Toast({
        type: 'success',
        message: 'user saved successfully',
      });
      setLoading(false);
      onClose();
      fetchMachines();
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };

  return (
    <Form form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      <Grid
        xs={1}
        lg={2}
        colGap={20}
        css={`
          align-items: center;
        `}>
        {!passwordOnly && !machine && (
          <Form.Item
            sm
            type="text"
            name="m_id"
            label="Machine ID"
            placeholder="ABC123"
            rules={[
              {
                required: true,
                message: 'Please input MachineID!',
              },
            ]}>
            <Field />
          </Form.Item>
        )}
        {!passwordOnly && (
          <Form.Item
            sm
            disabled={passwordOnly}
            name="m_name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please input machine name!',
              },
            ]}>
            <Field />
          </Form.Item>
        )}
        {!passwordOnly && (
          <Form.Item
            sm
            name="m_generation"
            label="Generation"
            rules={[
              {
                required: true,
                message: 'Please input machine generation!',
              },
            ]}>
            <Field />
          </Form.Item>
        )}
        {(passwordOnly || !machine) && (
          <Form.Item
            sm
            type="password"
            label="Password"
            name="m_password"
            placeholder="Password"
            rules={[
              {
                required: true,
              },
              { password: true },
            ]}>
            <Field />
          </Form.Item>
        )}
        {(passwordOnly || !machine) && (
          <Form.Item
            sm
            type="password"
            label="Confirm Password"
            name="m_confirm_password"
            placeholder="Confirm Password"
            rules={[
              {
                required: true,
              },
              {
                password: true,
              },
              {
                transform: value => value !== form.getFieldValue('m_password'),
                message: 'The two passwords that you entered do not match!',
              },
            ]}>
            <Field />
          </Form.Item>
        )}
        {!passwordOnly && (
          <SwitchHolerMachine>
            <Switch
              label="Status"
              name="m_status"
              checked={form.getFieldValue('m_status') === 'on'}
              onChange={({ target: { checked } }) => {
                form.setFieldsValue({ m_status: checked ? 'on' : 'off' });
              }}
            />
          </SwitchHolerMachine>
        )}
      </Grid>

      <Button loading={loading} type="primary" htmlType="submit">
        Save
      </Button>
    </Form>
  );
}

export default MachineForm;
