import React, { useContext } from 'react';

// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';
import { LoadingContext } from 'context/loadingContext';
import Header from 'components/molecules/Header';

import { AuthContext } from 'context/authContext';
import { useParams } from 'react-router-dom';
import MachineForm from 'components/organisms/MachineForm';
import ModalContainer from 'components/molecules/ModalContainer';
import Button from 'components/atoms/Button';
import { PageButtons } from 'helpers/headerButtons';
import UserForm from 'components/organisms/UserForm';

function TopBar() {
  const { isLoading } = useContext(LoadingContext);
  // eslint-disable-next-line no-unused-vars
  const { user } = useContext(AuthContext);
  // eslint-disable-next-line prefer-const
  let { view: title } = useParams();

  return (
    <>
      <Header title={title.split('-').join(' ')}>
        {isLoading ? (
          <Skeleton rectangle height={40} width={131} css="border-radius:8px !important;" />
        ) : (
          PageButtons?.[title]?.includes('add-machine') &&
          user?.role === 'admin' && (
            <ModalContainer
              lg
              title="Add Machine"
              btnComponent={({ onClick }) => (
                <Button
                  type="primary"
                  onClick={onClick}
                  iconMobile
                  prefix={<i className="material-icons-outlined">coffee_maker</i>}>
                  <span className="text">Add Machine</span>
                </Button>
              )}
              content={({ onClose }) => <MachineForm onClose={onClose} />}
            />
          )
        )}
        {isLoading ? (
          <Skeleton rectangle height={40} width={131} css="border-radius:8px !important;" />
        ) : (
          PageButtons?.[title]?.includes('add-user') &&
          user?.role === 'admin' && (
            <ModalContainer
              lg
              title="Add User"
              btnComponent={({ onClick }) => (
                <Button
                  type="primary"
                  onClick={onClick}
                  iconMobile
                  prefix={<i className="material-icons-outlined">person_add</i>}>
                  <span className="text">Add User</span>
                </Button>
              )}
              content={({ onClose }) => <UserForm onClose={onClose} />}
            />
          )
        )}
      </Header>
    </>
  );
}

export default TopBar;
