import { useEffect, useState } from 'react';
import { getTimeRangeFromText } from 'helpers/common';
import { useCancellablePromise } from '../helpers/promiseHandler';
import { Fetch } from '../helpers/fetchWrapper';

const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};
const MachineService = {
  _url: process.env.REACT_APP_BASE_API_URL,
  /**
   * Custom Fetch Hooks
   */

  GetCupChartData(searchQuery, refetch) {
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getCupChartData(searchQuery))
        .then(res => {
          setChartData(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      refetch,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.searchText,
      searchQuery?.filterText,
      searchQuery?.filterMachine,
    ]);
    return {
      chart_loading: status === STATUS.LOADING,
      chart_error: status === STATUS.ERROR ? status : '',
      chart_data: chartData,
    };
  },

  GetMachines(searchQuery = {}, refetch = false) {
    const [machines, setMachines] = useState({
      machines: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getMachines(searchQuery))
        .then(res => {
          setMachines(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      refetch,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.searchText,
      searchQuery?.filterText,
    ]);
    return {
      machines_loading: status === STATUS.LOADING,
      machines_error: status === STATUS.ERROR ? status : '',
      machines_data: machines,
    };
  },
  GetMachinesEvents(searchQuery = {}, refetch = false) {
    const [events, setEvents] = useState({
      events: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getMachinesEvents(searchQuery))
        .then(res => {
          setEvents(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      refetch,
      searchQuery?.page,
      searchQuery?.itemsPerPage,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.searchText,
      searchQuery?.filterText,
    ]);
    return {
      events_loading: status === STATUS.LOADING,
      events_error: status === STATUS.ERROR ? status : '',
      events_data: events,
    };
  },
  GetChartData(searchQuery = {}, refetch = false) {
    const [chartData, setChartData] = useState({
      chart_beer: {
        labels: [],
        datasets: [],
      },

      chart_water: {
        labels: [],
        datasets: [],
      },

      chart_keg: {
        labels: [],
        datasets: [],
      },
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getChartData(searchQuery))
        .then(res => {
          const labels = res.chart_beer.labels.map(_ => _);
          setChartData(() => ({
            chart_beer: {
              labels,
              datasets: res.chart_beer.datasets,
            },
            chart_water: {
              labels,
              datasets: res.chart_water.datasets,
            },
            chart_keg: {
              labels,
              datasets: res.chart_keg.datasets,
            },
          }));
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [refetch, searchQuery?.startDate, searchQuery?.endDate, searchQuery?.filterText]);
    return {
      chart_loading: status === STATUS.LOADING,
      chart_error: status === STATUS.ERROR ? status : '',
      chart_data: chartData,
    };
  },
  /**
   * Apis Calls
   */
  async getMachines({ page = 1, pageSize = 10, startDate = '', endDate = '', searchText = '', filterText = '' }) {
    if (filterText && !startDate) {
      const x = getTimeRangeFromText(filterText);
      startDate = x._startDate;
      endDate = x._endDate;
    } else if (startDate) {
      startDate = new Date(startDate).valueOf();
      endDate = new Date(endDate).valueOf();
    }
    const res = await Fetch.get(
      `${this._url}/machine?page=${page}&itemsPerPage=${pageSize}&startDate=${startDate}&endDate=${endDate}&searchText=${searchText}&filterText=${filterText}`,
    );
    if (res.status >= 200 && res.status < 300) {
      const data = await res.json();
      return {
        machines: data.items,
        totalItems: data.totalItems,
      };
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async addMachine(machine) {
    const res = await Fetch.post(`${this._url}/machine`, machine);
    if (res.status >= 200 && res.status < 300) {
      const data = await res.json();
      return data;
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async updateMachine(id, machine) {
    const res = await Fetch.put(`${this._url}/machine/${id}`, machine);
    if (res.status >= 200 && res.status < 300) {
      const data = await res.json();
      return data;
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async deleteMachine(id) {
    const res = await Fetch.delete(`${this._url}/machine/${id}`);
    if (res.status >= 200 && res.status < 300) {
      const data = await res.json();
      return data;
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async getMachinesEvents({
    page = 1,
    itemsPerPage = 10,
    startDate = '',
    endDate = '',
    searchText = '',
    filterText = '',
  }) {
    const res = await Fetch.get(
      `${this._url}/machine-event?page=${page}&itemsPerPage=${itemsPerPage}&startDate=${startDate}&endDate=${endDate}&searchText=${searchText}&filterText=${filterText}`,
    );
    if (res.status >= 200 && res.status < 300) {
      const data = await res.json();
      return {
        events: data.items,
        totalItems: data.totalItems,
      };
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async addMachineEvent(machineEvent) {
    const res = await Fetch.post(`${this._url}/machine-event`, machineEvent);
    if (res.status >= 200 && res.status < 300) {
      const data = await res.json();
      return data;
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async updateMachineEvent(id, machineEvent) {
    const res = await Fetch.put(`${this._url}/machine-event/${id}`, machineEvent);
    if (res.status >= 200 && res.status < 300) {
      const data = await res.json();
      return data;
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async deleteMachineEvent(id) {
    const res = await Fetch.delete(`${this._url}/machine-event/${id}`);
    if (res.status >= 200 && res.status < 300) {
      const data = await res.json();
      return data;
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async getChartData({ startDate = '', endDate = '', filterText = 'today_so_far' }) {
    const x = getTimeRangeFromText(filterText);
    if (!startDate || !endDate) {
      startDate = x._startDate;
      endDate = x._endDate;
    } else {
      startDate = new Date(startDate).valueOf();
      endDate = new Date(endDate).valueOf();
    }
    const res = await Fetch.get(
      `${this._url}/chart-data?startDate=${startDate}&endDate=${endDate}&timeZone=${x.timeZone}`,
    );
    if (res.status >= 200 && res.status < 300) {
      const data = await res.json();
      return data;
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async getMachineDetails(id) {
    const res = await Fetch.get(`${this._url}/machine/${id}`);
    if (res.status >= 200 && res.status < 300) {
      const data = await res.json();
      return data;
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async getCupChartData({ startDate = '', endDate = '', filterText = 'today_so_far', filterMachine = '' }) {
    if (!filterMachine) {
      return { labels: [], datasets: [] };
    }
    const x = getTimeRangeFromText(filterText);
    if (!startDate || !endDate) {
      startDate = x._startDate;
      endDate = x._endDate;
    } else {
      startDate = new Date(startDate).valueOf();
      endDate = new Date(endDate).valueOf();
    }
    const res = await Fetch.get(
      `${this._url}/cups-chart-data?startDate=${startDate}&endDate=${endDate}&timeZone=${x.timeZone}&machine=${filterMachine}`,
    );
    if (res.status >= 200 && res.status < 300) {
      const data = await res.json();
      return data;
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async getMachineNames() {
    const res = await Fetch.get(`${this._url}/machines`);
    if (res.status >= 200 && res.status < 300) {
      const data = await res.json();
      return { machines: data };
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
};

export default MachineService;
