import React, { useContext, useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';
import Select from 'components/atoms/Select';
import userService from 'services/userService';
import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';

function UserForm({ user, passwordOnly, onClose = () => {} }) {
  const [form] = useForm();
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const { fetchUsers } = useContext(AuthContext);

  useEffect(() => {
    if (user && !passwordOnly) {
      form.setFieldsValue({
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        role: { label: user.role, value: user.role },
      });
      setState({
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        role: { label: user.role, value: user.role },
      });
    }
  }, [user, passwordOnly]);
  const onSubmit = async data => {
    try {
      setLoading(true);
      if (user && !passwordOnly) {
        delete data.password;
        delete data.email;
        userService.updateUser(user._id, { ...data, role: data.role.value });
      } else if (user && passwordOnly) {
        await userService.updateUser(user._id, {
          password: data.password,
        });
      } else {
        await userService.addUser({
          ...data,
          role: data.role.value,
        });
      }
      Toast({
        type: 'success',
        message: 'user saved successfully',
      });
      fetchUsers();
      setLoading(false);
      onClose();
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };

  return (
    <Form form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      <Grid
        xs={1}
        lg={2}
        colGap={20}
        css={`
          align-items: center;
        `}>
        {!passwordOnly && (
          <Form.Item
            disabled={passwordOnly}
            sm
            type="email"
            name="email"
            label="Email"
            placeholder="Email"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
              },
              {
                email: true,
                message: 'Please input a valid email!',
              },
            ]}>
            <Field />
          </Form.Item>
        )}
        {!passwordOnly && (
          <Form.Item
            disabled={passwordOnly}
            sm
            name="first_name"
            label="First Name"
            placeholder="First Name"
            rules={[
              {
                required: true,
                message: 'Please input your first name!',
              },
            ]}>
            <Field />
          </Form.Item>
        )}
        {!passwordOnly && (
          <Form.Item
            disabled={passwordOnly}
            sm
            name="last_name"
            label="Last Name"
            placeholder="Last Name"
            rules={[
              {
                required: true,
                message: 'Please input your last name!',
              },
            ]}>
            <Field />
          </Form.Item>
        )}
        {(passwordOnly || !user) && (
          <Form.Item
            type="password"
            label="Password"
            name="password"
            placeholder="Password"
            rules={[
              {
                required: true,
              },
              { password: true },
            ]}>
            <Field />
          </Form.Item>
        )}
        {(passwordOnly || !user) && (
          <Form.Item
            type="password"
            label="Confirm Password"
            name="confirm_password"
            placeholder="Confirm Password"
            rules={[
              {
                required: true,
              },
              {
                password: true,
              },
              {
                transform: value => value !== form.getFieldValue('password'),
                message: 'The two passwords that you entered do not match!',
              },
            ]}>
            <Field />
          </Form.Item>
        )}
        {!passwordOnly && (
          <Form.Item
            sm
            name="role"
            label="Role"
            placeholder="Select Role"
            options={[
              {
                label: 'Admin',
                value: 'admin',
              },
              {
                label: 'User',
                value: 'user',
              },
            ]}
            rules={[
              {
                required: true,
                message: 'Please select a Role!',
              },
            ]}>
            <Select />
          </Form.Item>
        )}
      </Grid>

      <Button loading={loading} type="primary" htmlType="submit">
        Save
      </Button>
    </Form>
  );
}

export default UserForm;
