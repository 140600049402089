import { format } from 'date-fns';

/* eslint-disable no-plusplus */
export const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;

  return true;
};

export const getCookie = name => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const clearCookie = name => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

  return true;
};

export const capitalize = (str = '') => {
  const arr = str.toLowerCase().split(' ');

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(' ');
  return str2;
};

export const getDateObject = (e = '') => {
  const date = new Date(e);
  date.toLocaleTimeString('en-US', { timeZone: 'Canada/Eastern' });
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(date?.getTime())) {
    const x = new Date();
    x.toLocaleTimeString('en-US', { timeZone: 'Canada/Eastern' });
    return x;
  }
  return date;
};

export const convertToCurrencyFormat = (amount = '0') =>
  `$ ${Number(amount)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

export const shortenString = (str, len = 10) => {
  if (str.length > len) {
    return `${str.substring(0, len)}...`;
  }
  return str;
};

export const convertReadable = (amount = 0) =>
  `${
    Math.abs(amount) > 999
      ? `${Math.sign(amount) * (Math.abs(amount) / 1000).toFixed(1)}K`
      : Math.sign(amount) * Math.abs(amount)
  }`;
export const getTimeRangeFromText = (
  text = 'today_so_far',
  { startDate, endDate } = { startDate: null, endDate: null },
) => {
  if (startDate && endDate) {
    return {
      startDate: format(startDate, 'dd MMM yyyy hh:mm a'),
      endDate: format(endDate, 'dd MMM yyyy hh:mm a'),
    };
  }
  // eslint-disable-next-line no-param-reassign
  if (!text) text = 'today_so_far';
  const mapped_ranges = {
    last_hour: {
      startDate: new Date(new Date().setHours(new Date().getHours() - 1)),
      endDate: new Date(),
    },
    today_so_far: {
      startDate: new Date(new Date().setHours(0, 0, 0, 0)),
      endDate: new Date(),
    },
    last_24_hour: {
      startDate: new Date(new Date().setHours(new Date().getHours() - 24)),
      endDate: new Date(),
    },
    last_week: {
      startDate: new Date(new Date().setHours(new Date().getHours() - 24 * 7)),
      endDate: new Date(),
    },
    last_month: {
      startDate: new Date(new Date().setHours(new Date().getHours() - 24 * 30)),
      endDate: new Date(),
    },
    last_year: {
      startDate: new Date(new Date().setHours(new Date().getHours() - 24 * 365)),
      endDate: new Date(),
    },
  };
  const data = mapped_ranges[text.toLowerCase()];

  return {
    startDate: format(data.startDate, 'dd MMM yyyy hh:mm a'),
    endDate: format(data.endDate, 'dd MMM yyyy hh:mm a'),
    _startDate: data.startDate.valueOf(),
    _endDate: data.endDate.valueOf(),
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
};
