/* eslint-disable no-shadow */
import React, { lazy, useState, useEffect, useContext, Suspense } from 'react';
import { useParams } from 'react-router-dom';

import Loaders from 'components/atoms/Loaders';
import PageTemplate from '../components/templates/PageTemplate';
import { AuthContext } from '../context/authContext';
import pages from '../nav.json';

const importView = file =>
  // eslint-disable-next-line no-console
  lazy(() => import(`./${file}`).catch(err => console.log(`Error in importing ${file}`, { err })));

export default function Index({ history }) {
  const { loading_user, user } = useContext(AuthContext);
  const { view } = useParams();
  const metaViewData = pages;
  const [selectedView, setSelectedView] = useState([]);

  async function loadView(filtered) {
    const promise = filtered.map(async _view => {
      const View = await importView(_view.file);
      // eslint-disable-next-line no-use-before-define
      return <View key={_view.id} selectView={selectView} />;
    });
    Promise.all(promise).then(setSelectedView);
  }
  async function selectView(file) {
    const filtered = metaViewData
      .map(({ subNav = [], ...item }) => [item, ...subNav])
      .flat(Infinity)
      .filter(elem => elem.file === file);
    loadView([filtered[0]]);
  }

  useEffect(() => {
    let fileToLoad = view;
    if (user.role) {
      if (fileToLoad === 'null' || fileToLoad === 'revolmatic/null') {
        fileToLoad = 'dashboard';
      }

      if (!metaViewData.find(elem => elem.file === fileToLoad && elem.roles.includes(user.role))) {
        fileToLoad = 'dashboard';
      }

      if (history.location.pathname.split('/').length > 3) {
        history.push(`/revolmatic/dashboard`);
      } else
        history.replace({
          pathname: fileToLoad,
          isActive: true,
        });
      selectView(fileToLoad);
    }
  }, [view, loading_user]);

  return (
    <>
      {loading_user ? (
        <Loaders pageLoader />
      ) : (
        <PageTemplate
          title={view}
          showTemplate={metaViewData?.filter(elem => elem.file === view)[0]?.navigations}
          topBar>
          <Suspense fallback={<Loaders viewLoader />}>{selectedView}</Suspense>
        </PageTemplate>
      )}
    </>
  );
}
