import { useEffect, useState } from 'react';
import { setCookie } from '../helpers/common';
import { Fetch } from '../helpers/fetchWrapper';
import { useCancellablePromise } from '../helpers/promiseHandler';

const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};
const UserService = {
  _url: process.env.REACT_APP_BASE_API_URL,
  /**
   * Custom Fetch Hooks
   */
  GetUsers(searchQuery = {}, refetch = false) {
    const [users, setUsers] = useState({
      users: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getUsers(searchQuery))
        .then(res => {
          setUsers(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      refetch,
      searchQuery?.page,
      searchQuery?.itemsPerPage,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.searchText,
      searchQuery?.filterText,
    ]);
    return {
      users_loading: status === STATUS.LOADING,
      users_error: status === STATUS.ERROR ? status : '',
      users_data: users,
    };
  },
  /**
   * Apis Calls
   */
  async login(email, password) {
    let res = await Fetch.post(`${this._url}/login`, {
      email,
      password,
    });
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      const { token, user } = res;
      setCookie('revolmatic_admin_token', token);
      return user;
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async logout() {
    const res = await Fetch.post(`${this._url}/logout`, {});
    if (res.status >= 200 && res.status < 300) {
      return true;
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async getUsers({ page = 1, pageSize = 10, startDate = '', endDate = '', searchText = '', filterText = '' }) {
    const res = await Fetch.get(
      `${this._url}/user?page=${page}&itemsPerPage=${pageSize}&startDate=${startDate}&endDate=${endDate}&searchText=${searchText}&filterText=${filterText}`,
    );
    if (res.status >= 200 && res.status < 300) {
      const data = await res.json();
      return {
        users: data.items,
        totalItems: data.totalItems,
      };
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async getCurrentUser() {
    const res = await Fetch.get(`${this._url}/me`);
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async updateUser(id, values) {
    const res = await Fetch.put(`${this._url}/user/${id}`, values);
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async deleteUser(id) {
    const res = await Fetch.delete(`${this._url}/user/${id}`);
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async addUser(values) {
    const res = await Fetch.post(`${this._url}/user`, values);
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
};

export default UserService;
