/* eslint-disable no-unreachable */
import Toast from 'components/molecules/Toast';
import { clearCookie, getCookie } from 'helpers/common';
import { useCancellablePromise } from 'helpers/promiseHandler';
import React, { createContext, useEffect, useState } from 'react';
import UserService from 'services/userService';

const context = {};
export const AuthContext = createContext(context);
export const AuthContextProvider = props => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!getCookie('revolmatic_admin_token'));
  const [user, setUser] = useState({});
  const [fetchUser, setFetchUser] = useState(false);
  const [fetchMachines, setFetchMachines] = useState(false);
  const [fetchMachineEvents, setFetchMachineEvents] = useState(false);
  const [loading_user, setLoadingUser] = useState(false);
  const { cancellablePromise } = useCancellablePromise();
  const onLogout = () => {
    if (getCookie('revolmatic_admin_token')) clearCookie('revolmatic_admin_token');
    setIsLoggedIn(false);
    setUser({});
  };
  const onLogin = ({ email, password }) =>
    UserService.login(email, password)
      .then(res => {
        setIsLoggedIn(() => true);
        setUser(() => ({ ...res }));
        return true;
      })
      .catch(({ message }) => {
        setIsLoggedIn(false);
        Toast({
          message,
          type: 'error',
        });
      });
  useEffect(() => {
    if (isLoggedIn) {
      setLoadingUser(true);
      cancellablePromise(UserService.getCurrentUser())
        .then(res => {
          setUser(() => ({ ...res }));
          setLoadingUser(false);
        })
        .catch(() => {
          onLogout();
          setLoadingUser(false);
        });
    }
  }, [isLoggedIn]);
  return (
    <AuthContext.Provider
      value={{
        setIsLoggedIn,
        onLogout,
        onLogin,
        fetchUsers: () => setFetchUser(!fetchUser),
        fetchMachines: () => setFetchMachines(!fetchMachines),
        fetchMachineEvents: () => setFetchMachineEvents(!fetchMachineEvents),
        fetch_user: fetchUser,
        fetch_mechine: fetchMachines,
        fetch_machine_events: fetchMachineEvents,
        isLoggedIn,
        loading_user,
        user,
      }}>
      {props.children}
    </AuthContext.Provider>
  );
};
